import type { CellProps, Column } from 'react-table';
import {
  DisabledBadge,
  getEnabledRadioForBandOrNull,
  isDefined,
  NeutralBadge,
  Tooltip,
} from '@meterup/common';
import { Badge, DeviceIcon, DeviceIconSize, HStack, space } from '@meterup/metric';
import { api } from '@meterup/proto';
import React from 'react';

import type { DeviceDataAndRadios } from '../api/types';
import { CellDeviceStatus } from '../components/Table/tableCells';
import { styled } from '../stitches';

const DeviceName = styled('div', { hStack: '$8' });

export const RadioNumbers = ({ value }: { value: api.AccessPointRadioResponse }) => (
  <HStack spacing={space(4)}>
    <Tooltip content="Channel">
      <Badge size="small">{value.channel}</Badge>
    </Tooltip>
    <Tooltip content="Channel width">
      <Badge size="small">{value.channel_width}</Badge>
    </Tooltip>
    <Tooltip content="Power">
      <Badge size="small">{value.power}</Badge>
    </Tooltip>
  </HStack>
);

export const RadioNumbersOrDisabled = ({
  value,
  band,
}: {
  value: api.AccessPointRadiosResponse | null | undefined;
  band: api.RadioBand;
}) => {
  const radio = getEnabledRadioForBandOrNull(value, band);
  return isDefined(radio) ? (
    <RadioNumbers value={radio} />
  ) : (
    <DisabledBadge icon="crossCircle" arrangement="leading-icon" />
  );
};

const hasDefinedRadio = (device: DeviceDataAndRadios | null | undefined) =>
  isDefined(device) && isDefined(device.apAndRadios);

export const formatAPRadiosForBand = (
  accessPoint: api.AccessPointRadiosResponse | null,
  band: api.RadioBand,
) => {
  const radio = getEnabledRadioForBandOrNull(accessPoint, band);
  return radio
    ? [radio.channel.toString(), radio.channel_width.toString(), radio.power.toString()].join(', ')
    : null;
};

export const DEVICE_LIST_COLUMNS: Column<DeviceDataAndRadios>[] = [
  {
    Header: 'Location',
    accessor: (d) => (isDefined(d.device) ? d.device.physical_location ?? d.device.name : null),
    Cell: ({ value }: { value: string }) => (
      <DeviceName>
        <DeviceIcon size={DeviceIconSize.Small} /> {value}
      </DeviceName>
    ),
  },
  {
    Header: '5G band',
    accessor: (d) =>
      hasDefinedRadio(d) ? formatAPRadiosForBand(d.apAndRadios, api.RadioBand.RB_5G) : null,
    Cell: (props: CellProps<any>) => {
      if (hasDefinedRadio(props.row.original)) {
        const value = getEnabledRadioForBandOrNull(
          props.row.original.apAndRadios,
          api.RadioBand.RB_5G,
        );
        return value ? <RadioNumbers value={value} /> : <DisabledBadge />;
      }
      return <DisabledBadge />;
    },
  },
  {
    Header: '2.4G band',
    accessor: (d) =>
      hasDefinedRadio(d) ? formatAPRadiosForBand(d.apAndRadios, api.RadioBand.RB_2G) : null,
    Cell: (props: CellProps<any>) => {
      if (hasDefinedRadio(props.row.original)) {
        const value = getEnabledRadioForBandOrNull(
          props.row.original.apAndRadios,
          api.RadioBand.RB_2G,
        );
        return value ? <RadioNumbers value={value} /> : <DisabledBadge />;
      }
      return <DisabledBadge />;
    },
  },
  {
    Header: 'Version',
    accessor: (d) => {
      if (hasDefinedRadio(d)) {
        return d.apAndRadios?.access_point?.version;
      }
      return null;
    },
    Cell: ({ value }: { value: string }) => <NeutralBadge>{value}</NeutralBadge>,
  },
  {
    Header: 'Status',
    accessor: (d) => (isDefined(d.device) ? d.device.status : null),
    Cell: CellDeviceStatus,
  },
  {
    Header: 'Clients',
    accessor: (d) => (isDefined(d.device) ? d.device.clients : null),
  },
];

export enum DevicesFilterStrategy {
  All = 'all',
  Online = 'online',
  Offline = 'offline',
  Draft = 'draft',
}

export const isOnline = (d: DeviceDataAndRadios) =>
  d.device.status === DevicesFilterStrategy.Online;
export const isOffline = (d: DeviceDataAndRadios) =>
  d.device.status === DevicesFilterStrategy.Offline;
export const isDraft = (d: DeviceDataAndRadios) => d.device.status === DevicesFilterStrategy.Draft;
